
	<div class="container">

	
	<div class="row mt-5 pt-3 pb-3">
	<div class="d-flex justify-content-center">
	
	<div class="col-md-4">
	<h3 class="text-center mb-3 text-justify"><strong>Reset Password</strong></h3>
	<p style="font-size:14px;">Your password must be atleast 6 characters and shuld include a combination of numbers, letters and special characters(!@#$%)</p>



<div class="form-row mt-5"> 
	  
        <div class="form-group col-md-12">  
		 <label for="" class="form-label float-start">First name*</label>
          <input type="text"  class="form-control" placeholder="First Name"> 
		  <span>Password is case sensitive</span>
        </div>  
        <div class="form-group col-md-12 mt-3"> 
		<label for="" class="form-label float-start">Middle name</label>
          <input type="text" class="form-control" placeholder="Middle Name">
		  <span>Wrong password</span>
        </div> 
		
     
 </div> 



	</div>
	</div>
	
	</div>
	<section class="multi_step_form">  
  <form id="msform"> 
	<fieldset>
	
	<div class="form-row mt-2"> 
	<div class="col-md-12">
	<button type="button" class="action-button previous previous_button float-start mt-3">Back</button> 
       <button type="button" class="next action-button float-end mt-3">Continue</button>  
	   </div>
</div>

</fieldset>

<!-- </section>   -->
  </form> 
	
	<!-- </div> -->
	



