<div
  class="offcanvas offcanvas-start"
  tabindex="-1"
  id="offcanvasExample"
  aria-labelledby="offcanvasExampleLabel"
>
  <div class="offcanvas-header">
    <img src="/assets/img/Logo.png" class="float-end" />
  </div>
  <div class="offcanvas-body nopadding pdrl0">
    <ul class="navbar-nav justify-content-end flex-grow-1 menutxt heallink">
      <li class="nav-item">
        <a
          class="nav-link heallink active"
          aria-current="page"
          href="{{ activeurl }}"
          (click)="redirect()"
          >Dashboard</a
        >
      </li>
      <li class="nav-item" *ngIf="usertype == 2">
        <a class="nav-link heallink" href="/profile" (click)="OnprofileSelect()"
          >Profile</a
        >
      </li>
      <li class="nav-item" *ngIf="usertype == 3">
        <a
          class="nav-link heallink"
          href="/onboarding"
          (click)="OnprofileSelect()"
          >Hospital Onboarding</a
        >
      </li>
      <li class="nav-item" *ngIf="usertype == '2'">
        <a class="nav-link heallink">Reports</a>
      </li>
      <li class="nav-item dropdown" *ngIf="usertype != '2'">
        <a class="nav-link heallink" href="/reports" (click)="OnreportSelect()"
          >Reports</a
        >
        <!-- <a class="nav-link dropdown-toggle heallink" href="/reports" (click)="OnreportSelect()" href="/reports" id="reportsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Reports
            </a> -->
        <!-- <ul class="dropdown-menu" aria-labelledby="reportsDropdown">
              <a class="dropdown-item" href="javascript:void(0);" (click)="OnReportSelect('Claims-Settlement')">Claims-Settlement</a>
              <a class="dropdown-item" href="javascript:void(0);" (click)="OnReportSelect('Daily-Denial')">Daily-Denial</a>
            </ul> -->
      </li>
    </ul>
  </div>
</div>
