import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tpa-approval',
  templateUrl: './tpa-approval.component.html',
  styleUrls: ['./tpa-approval.component.css']
})
export class TpaApprovalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
