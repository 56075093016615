// import { Component } from '@angular/core';

// @Component({
//   selector: 'app-error-message',
//   templateUrl: './error-message.component.html',
//   styleUrls: ['./error-message.component.css']
// })
// export class ErrorMessageComponent {

// }

import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css'],
})
export class ErrorMessageComponent {
  @Input() control: AbstractControl | null;
  @Input() errorMessage: string = 'This field is invalid';

  shouldShowError(): boolean {
    return (
      !!this.control &&
      this.control.invalid &&
      (this.control.dirty || this.control.touched)
    );
  }
}
