<!-- <div mat-dialog-content>
 <h2 mat-dialog-title>Session Timedout</h2>
  
</div>
<div mat-dialog-actions>
 
  <button type="button"  class="mat-raised-button" (click)="onNoThanks()">No Thanks</button>
  <button type="button" class="mat-raised-button mat-primary" (click)="onokClick(false)">Ok</button>

</div> -->


<div mat-dialog-content   class="mat-dialog-content">
  <h2>Session Timedout</h2>
  <mat-dialog-actions align="center"  >
    <button mat-raised-button color="primary" (click)="onokClick(false)" tabindex="1">Ok</button>
    <button mat-raised-button color="primary" mat-dialog-close tabindex="-1" (click)="onNoThanks()">No Thanks</button>
  </mat-dialog-actions>
</div>