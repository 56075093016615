<form [formGroup]="hospitalForm" (ngSubmit)="onSubmit()">
  <div class="container" style="background-color: #f9feff; height: full">
    <div class="row pt-3 pb-3">
      <div class="col-md-8 p-5">
        <div class="row">
          <div class="col-md-2">
            <img src="/assets/img/Ellipse41.png" alt="Hospital Logo" />
          </div>
          <div class="col-md-10 p-3">
            <h3 class="float-start">
              <input
                type="text"
                formControlName="hospitalName"
                class="form-control custom-input"
                placeholder="Hospital Name"
                [ngClass]="{
                  'is-invalid':
                    hospitalForm.get('hospitalName')?.invalid &&
                    hospitalForm.get('hospitalName')?.touched
                }"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  hospitalForm.get('hospitalName')?.invalid &&
                  hospitalForm.get('hospitalName')?.touched
                "
              >
                <div
                  *ngIf="hospitalForm.get('hospitalName')?.hasError('required')"
                >
                  hospital Name is required.
                </div>
              </div>
            </h3>
          </div>
        </div>
        <div class="row mt-5">
          <h5><strong>About us</strong></h5>
          <hr class="mx-2 mt-2 mb-2" style="width: 90%" />
          <div class="my-2 profiletxt">
            <textarea
              formControlName="aboutUs"
              class="form-control"
              placeholder="About Us"
              [ngClass]="{
                'is-invalid':
                  hospitalForm.get('aboutUs')?.invalid &&
                  hospitalForm.get('aboutUs')?.touched
              }"
            ></textarea>
            <div
              class="invalid-feedback"
              *ngIf="
                hospitalForm.get('aboutUs')?.invalid &&
                hospitalForm.get('aboutUs')?.touched
              "
            >
              <div *ngIf="hospitalForm.get('aboutUs')?.hasError('required')">
                About Us is required.
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3 mx-3">
          <label for="hasLoan" class="form-label"
            >Virtual cashless offered?</label
          >
          <div class="form-check" style="margin-top: 5px">
            <input
              type="checkbox"
              id="hasLoan"
              [(ngModel)]="hospitalForm.value.hasLoan"
              name="hasLoan"
              class="form-check-input"
              (change)="onHasLoanChange($event)"
            />

            <label class="form-check-label" for="hasLoan">Yes</label>
          </div>
        </div>

        <!-- Members Section -->
        <div class="row mt-5">
          <h5><strong>Members</strong></h5>
          <hr class="mx-2 mt-2 mb-2" style="width: 90%" />

          <!-- Member Form -->
          <div class="col-md-6">
            <div class="mb-3">
              <label for="firstname" class="form-label">First Name:</label>
              <input
                type="text"
                id="firstname"
                #firstname
                formControlName="firstname"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    hospitalForm.get('firstname')?.invalid &&
                    hospitalForm.get('firstname')?.touched
                }"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  hospitalForm.get('firstname')?.invalid &&
                  hospitalForm.get('firstname')?.touched
                "
              >
                <div
                  *ngIf="hospitalForm.get('firstname')?.hasError('required')"
                >
                  First Name is required.
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="firstname" class="form-label">Last Name:</label>
              <input
                type="text"
                id="lastname"
                formControlName="lastname"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    hospitalForm.get('lastname')?.invalid &&
                    hospitalForm.get('lastname')?.touched
                }"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  hospitalForm.get('lastname')?.invalid &&
                  hospitalForm.get('lastname')?.touched
                "
              >
                <div *ngIf="hospitalForm.get('lastname')?.hasError('required')">
                  Last Name is required.
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="contact" class="form-label">Contact:</label>
              <input
                type="text"
                id="contact"
                formControlName="contact"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    hospitalForm.get('contact')?.invalid &&
                    hospitalForm.get('contact')?.touched
                }"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  hospitalForm.get('contact')?.invalid &&
                  hospitalForm.get('contact')?.touched
                "
              >
                <div *ngIf="hospitalForm.get('contact')?.hasError('required')">
                  Contact is required.
                </div>
                <div *ngIf="hospitalForm.get('contact')?.hasError('pattern')">
                  Contact must contain only numeric digits.
                </div>
                <div *ngIf="hospitalForm.get('contact')?.hasError('minlength')">
                  Contact must be 10 digits long.
                </div>
                <div *ngIf="hospitalForm.get('contact')?.hasError('maxlength')">
                  Contact must be 10 digits long.
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label for="email" class="form-label">Email:</label>

              <input
                type="email"
                id="email"
                formControlName="email"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    hospitalForm.get('email')?.invalid &&
                    hospitalForm.get('email')?.touched
                }"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  hospitalForm.get('email')?.invalid &&
                  hospitalForm.get('email')?.touched
                "
              >
                <div *ngIf="hospitalForm.get('email')?.hasError('required')">
                  Email ID is required.
                </div>
                <div *ngIf="hospitalForm.get('email')?.hasError('email')">
                  Invalid email format.
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label for="designation" class="form-label">designation:</label>
              <input
                type="designation"
                id="designation"
                formControlName="designation"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    hospitalForm.get('designation')?.invalid &&
                    hospitalForm.get('designation')?.touched
                }"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  hospitalForm.get('designation')?.invalid &&
                  hospitalForm.get('designation')?.touched
                "
              >
                <div
                  *ngIf="hospitalForm.get('designation')?.hasError('required')"
                >
                  Designation is required.
                </div>
              </div>
            </div>
          </div>

          <!-- Inside the form -->
          <!-- <div class="mb-3">
            <button
              type="button"
              class="btn btn-primary"
              (click)="addContact()"
            >
              Add Member
            </button>
          </div> -->

          <!-- Display Members -->
          <!-- <div class="col-md-6">
            <div class="mb-3">
              <label><strong>Member List</strong></label>
              <ul>
                <li *ngFor="let member of members">
                  {{ member.firstname }} - {{ member.contact }} -
                  {{ member.email }}
                </li>
              </ul>
            </div>
          </div> -->
        </div>
        <!-- End of Members Section -->

        <!-- ... -->
        <button
          type="submit"
          class="w-100 btn btn-lg btn-primary mt-5"
          style="font-size: 18px; margin-top: -10px"
          [disabled]="loading"
        >
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span *ngIf="!loading">Submit</span>
        </button>

        <!--
        <button
          type="submit"
          class="w-100 btn btn-lg btn-primary mt-5"
          style="font-size: 18px; margin-top: -10px"
        >
          Submit
        </button> -->
      </div>
      <div class="col-md-4 leftpart p-5 healshadow">
        <h5 class="mb-3"><strong>Address</strong></h5>
        <input
          type="text"
          formControlName="address"
          class="form-control"
          placeholder="Address"
          [ngClass]="{
            'is-invalid':
              hospitalForm.get('address')?.invalid &&
              hospitalForm.get('address')?.touched
          }"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            hospitalForm.get('address')?.invalid &&
            hospitalForm.get('address')?.touched
          "
        >
          <div *ngIf="hospitalForm.get('address')?.hasError('required')">
            Address is required.
          </div>
        </div>

        <!-- <h5 class="mb-3"><strong>Contact Number</strong></h5>
        <p class="mb-5">
          <input
            type="text"
            formControlName="contactNumber"
            class="form-control"
            placeholder="Contact Number"
          />
        </p> -->
        <h5 class="mb-3"><strong>Contact Number</strong></h5>
        <input
          type="text"
          formControlName="contactNumber"
          class="form-control"
          placeholder="Contact Number"
          [ngClass]="{
            'is-invalid':
              hospitalForm.get('contactNumber')?.invalid &&
              hospitalForm.get('contactNumber')?.touched
          }"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            hospitalForm.get('contactNumber')?.invalid &&
            hospitalForm.get('contactNumber')?.touched
          "
        >
          <div *ngIf="hospitalForm.get('contactNumber')?.hasError('required')">
            Contact is required.
          </div>
          <div *ngIf="hospitalForm.get('contactNumber')?.hasError('pattern')">
            Contact must contain only numeric digits.
          </div>
          <div *ngIf="hospitalForm.get('contactNumber')?.hasError('minlength')">
            Contact must be 10 digits.
          </div>
          <div *ngIf="hospitalForm.get('contactNumber')?.hasError('maxlength')">
            Contact must be 10 digits.
          </div>
        </div>
        <!--
        <h5 class="mb-3"><strong>Email ID</strong></h5>
        <p class="mb-5">
          <input
            type="email"
            formControlName="emailId"
            class="form-control"
            placeholder="Email ID"
          />
        </p> -->

        <div class="mb-3">
          <h5 class="mb-3"><strong>Email ID</strong></h5>
          <input
            type="email"
            id="emailId"
            formControlName="emailId"
            class="form-control"
            placeholder="Email ID"
            [ngClass]="{
              'is-invalid':
                hospitalForm.get('emailId')?.invalid &&
                hospitalForm.get('emailId')?.touched
            }"
          />
          <div
            class="invalid-feedback"
            *ngIf="
              hospitalForm.get('emailId')?.invalid &&
              hospitalForm.get('emailId')?.touched
            "
          >
            <div *ngIf="hospitalForm.get('emailId')?.hasError('required')">
              Email ID is required.
            </div>
            <div *ngIf="hospitalForm.get('emailId')?.hasError('email')">
              Invalid email format.
            </div>
          </div>
        </div>

        <h5 class="mb-3"><strong>GST number</strong></h5>
        <input
          type="text"
          formControlName="gstNumber"
          class="form-control"
          placeholder="GST Number"
          [ngClass]="{
            'is-invalid':
              hospitalForm.get('gstNumber')?.invalid &&
              hospitalForm.get('gstNumber')?.touched
          }"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            hospitalForm.get('gstNumber')?.invalid &&
            hospitalForm.get('gstNumber')?.touched
          "
        >
          <div *ngIf="hospitalForm.get('gstNumber')?.hasError('required')">
            GST Number is required.
          </div>
        </div>

        <h5 class="mb-3"><strong>Rohini Hospital ID</strong></h5>
        <input
          type="text"
          formControlName="hospitalId"
          class="form-control"
          placeholder="Rohini Hospital ID"
          [ngClass]="{
            'is-invalid':
              hospitalForm.get('hospitalId')?.invalid &&
              hospitalForm.get('hospitalId')?.touched
          }"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            hospitalForm.get('hospitalId')?.invalid &&
            hospitalForm.get('hospitalId')?.touched
          "
        >
          <div *ngIf="hospitalForm.get('hospitalId')?.hasError('required')">
            HospitalId Number is required.
          </div>
        </div>
        <hr />
        <!-- <a class="healtbv text-center mx-3 pt-3 pb-3 w-100">Reset Password</a> -->
      </div>
    </div>
  </div>
</form>
