import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { CommonserviceService } from 'src/app/service/commonservice.service';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import * as Papa from 'papaparse';
import bootstrap from 'src/assets/js/bootstrap';

interface CSVRow {
  [key: string]: string;
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit {
  usertype: any;
  selectedOption: string;
  csvData: string = '';
  delimiter: string = '';
  isDropdownOpen = false;
  isLoading = false;
  selectedReport: string | null = null;
  displayOptionSelected = false;
  downloadOptionSelected = false;
  structuredData: string[][] = [];
  currentPage = 1;
  pageSize = 10;
  totalItems = 0;

  // getToNext(){

  // }

  constructor(
    private commonservice: CommonserviceService,
    private http: HttpClient,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.usertype = sessionStorage.getItem('usertype');
    new bootstrap.Dropdown(document.getElementById('reportsDropdown'));
  }

  toggleDropdown() {
    const dropdownMenu = document.getElementById('reportsDropdown');
    if (dropdownMenu) {
      dropdownMenu.classList.toggle('show');
    }
  }

  listenForClickOutside() {
    this.renderer.listen('document', 'click', () => {
      this.toggleDropdown();
    });
  }

  toggleDisplay() {
    this.displayOptionSelected = true;
    this.downloadOptionSelected = false;
  }

  toggleDownload() {
    this.displayOptionSelected = false;
    this.downloadOptionSelected = true;
  }

  okClicked() {
    if (this.selectedReport) {
      if (this.displayOptionSelected) {
        this.OnReportSelect(this.selectedReport);
      } else if (this.downloadOptionSelected) {
        this.OnReportSelect1(this.selectedReport);
      }

      const displayRadioButton = document.getElementById(
        'displayOption'
      ) as HTMLInputElement;
      const downloadRadioButton = document.getElementById(
        'downloadOption'
      ) as HTMLInputElement;
      if (displayRadioButton && downloadRadioButton) {
        displayRadioButton.checked = false;
        downloadRadioButton.checked = false;
      }

      // this.selectedReport = null;
      this.toggleDropdown();
    }
  }

  getKeyValues(): any {
    let arr: any = [];
    this.structuredData.forEach((el) => {
      arr.push(Object.values(el));
    });
    console.log('arr', arr);
    return arr;
  }
  preventDropdownClose() {
    this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
      event.stopPropagation();
    });
  }

  setReport(report: string) {
    this.selectedReport = report;
    if (this.structuredData.length > 0) {
      this.OnReportSelect(report);
    }
  }

  OnReportSelect1(report: string) {
    let activeDashboard = sessionStorage.getItem('usertype');
    if (activeDashboard !== '2') {
      this.isLoading = true;
      let apiUrl = `${environment.baseUrlDocgen}api/retrieve/send_report`;
      // let apiUrl = `${environment.baseUrlDocgen}api/retrieve/send_report/${report}`;
      this.http
        .post(
          apiUrl,
          { report_type: report, page_number: 1, items_per_page: 5 },
          { responseType: 'text' }
        )
        .subscribe(
          (data: any) => {
            this.isLoading = false;

            const csvContent = data.replace(/\r?\n/g, '\r\n');

            const blob = new Blob([csvContent], {
              type: 'text/csv;charset=utf-8;',
            });

            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.setAttribute('download', `${report}.csv`);
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(url);
          },
          (error) => {
            this.isLoading = false;
            console.error('Error fetching the CSV file:', error);
          }
        );
    } else {
      console.log('Hospital users are not allowed to access this feature.');
    }
  }

  OnReportSelect(report: string) {
    let activeDashboard = sessionStorage.getItem('usertype');
    if (activeDashboard !== '2') {
      this.isLoading = true;
      let apiUrl = `${environment.baseUrlDocgen}api/retrieve/view_report`;
      this.http
        .post(
          apiUrl,
          {
            report_type: report,
            page_number: this.currentPage,
            items_per_page: this.pageSize,
          }
          // { responseType: 'text' }
        )
        .subscribe(
          (data: any) => {
            console.log('vvvv', this.isLoading);
            this.isLoading = false;
            // this.csvData = data?.text;
            console.log('vvvvvvvvvvv', data.length);
            this.structuredData = data?.text;
            this.totalItems = data.length;
            console.log('selkect', this.selectedReport, this.structuredData);
          },
          (error) => {
            this.isLoading = false;
            console.error('Error fetching the CSV file:', error);
          }
        );
    } else {
      console.log('Hospital users are not allowed to access this feature.');
    }
  }

  onPageChange(event: any): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    if (this.selectedReport) {
      this.OnReportSelect(this.selectedReport);
    }
  }

  private parseCSV(csvData: string): string[][] {
    const rows = csvData.split('\n');
    const structuredData: string[][] = [];
    for (const row of rows) {
      const columns = row.split(',');
      structuredData.push(columns);
    }

    console.log('strrrr', structuredData);

    return structuredData;
  }

  convertCsvToJson = (csvData: any) => {
    const lines = csvData.split('\n');
    const headers = lines[0].split(',');
    const result = [];

    for (let i = 1; i < lines.length; i++) {
      const obj: any = {};
      const currentLine = lines[i].split(',');

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j].trim()] = currentLine[j].trim();
      }

      result.push(obj);
    }

    return result;
  };
}
