<div class="container">
	<div class="row mt-5 pt-3 pb-3">
		<div class="col-md-6">
			<h3><i class='fa fa-arrow-left' (click)="goBack()"></i>{{SlaHeader}}</h3>
		</div>
		<div class="col-md-6">
			

			<div class="modal" role="dialog" id="modalChoice3">
				<div class="modal-dialog healm" role="document">



					<ul class="healmenulist">
						<a href="#">
							<li class="healmenu">Inital Authorization</li>
						</a>
						<a href="#">
							<li class="healmenu">Enhance</li>
						</a>
						<a href="#">
							<li class="healmenu">Discharge</li>
						</a>
						<a href="#">
							<li class="healmenu">Final Claim</li>
						</a>

					</ul>





				</div>
			</div>



		</div>
		<div></div>
	</div>




	<div class="row">


		<!-- <table class="table tstriped table-borderless mt-3 healshadow1">
	<thead>
		<th>Claim ID</th>
		<th>Name</th>
		<th>Ailment</th>
		<th>SLA</th>
		<th>P-TAT</th>
		<th>Stage</th>
		<th>Approved Amount</th>
		<th>Hospital</th>
	</thead>
	<tbody>
		<tr>
			<td><a href="" class="tblink">12345678</a></td>
			<td>Saravanan</td>
			<td>Heart surgery</td>
			<td>25%</td>
			<td>-</td>
			<td>Pending Approval</td>
			<td>Rs. 11,23,000</td>
			<td>Sant Parmanand Hospital</td>
		</tr>
		<tr>
			<td><a href="" class="tblink">12345678</a></td>
			<td>Saravanan</td>
			<td>Heart surgery</td>
			<td>25%</td>
			<td>-</td>
			<td>Pending Approval</td>
			<td>Rs. 11,23,000</td>
			<td>Sant Parmanand Hospital</td>
		</tr>
		<tr>
			<td><a href="" class="tblink">12345678</a></td>
			<td>Saravanan</td>
			<td>Heart surgery</td>
			<td>25%</td>
			<td>-</td>
			<td>Pending Approval</td>
			<td>Rs. 11,23,000</td>
			<td>Sant Parmanand Hospital</td>
		</tr><tr>
			<td><a href="" class="tblink">12345678</a></td>
			<td>Saravanan</td>
			<td>Heart surgery</td>
			<td>25%</td>
			<td>-</td>
			<td>Pending Approval</td>
			<td>Rs. 11,23,000</td>
			<td>Sant Parmanand Hospital</td>
		</tr>
		<tr>
			<td><a href="" class="tblink">12345678</a></td>
			<td>Saravanan</td>
			<td>Heart surgery</td>
			<td>25%</td>
			<td>-</td>
			<td>Pending Approval</td>
			<td>Rs. 11,23,000</td>
			<td>Sant Parmanand Hospital</td>
		</tr>
	</tbody>
</table> -->

<div class="search-hero w-100 p-1">
	<input class="form-control mt-2" type="text" name="filter" [(ngModel)]="searchText" autocomplete="off"
		placeholder="Search Here" />
</div>
<div class="table table-responsive fixTableHead">
		<table class="table tstriped table-borderless healshadow1">
			<thead style="position: sticky !important; top:0px !important;" class="theadheal">
				<th>Claim ID
				</th>
				<th>Name &nbsp;
				</th>
				<th>Ailment &nbsp;</th>
				<th>P-TAT &nbsp;</th>
				<th>Stage &nbsp;</th>
				<th>Status &nbsp;</th>
				<th>Approved Amount &nbsp;</th>
				<th>Hospital &nbsp;</th>
			</thead>
			<tbody>
				<tr *ngFor="let h of slaList | filter: searchText">
					<td><a class="tblink">{{h.healspanClaimID}}</a></td>
					<td>{{h.name}}</td>
					<td>{{h.ailment}}</td>
					<!-- <td>{{h.ptat}}</td> -->
					<td>{{h.stage}}</td>
					<td>{{h.status}}</td>
					<td>{{h.approvedAmount}}</td>
					<td>{{h.Hospital}}</td>
				</tr>
			</tbody>
		</table>

</div>
	</div>


</div>