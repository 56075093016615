<div class="container">


	<div class="row mb-3 mt-5 text-center justify-content-center" *ngIf="showApproval">
		<a class="col-md-3 text-decoration-none" (click)="updateSearchText('Initial Authorization')">
			<div class="healboxx healshadow1" [ngClass]="{'selected': searchText === 'Initial Authorization'}">
				<p><strong>Initial Authorization</strong></p>
				<h2 class="bxt"><strong>{{aprrovalDataList?.initialAuthorizationCount}}</strong></h2>
				<p class="bxtt">Approval</p>
			</div>
		</a>

		<a class="col-md-3 text-decoration-none" (click)="updateSearchText('Enhancement')">
			<div class="healboxx healshadow1" [ngClass]="{'selected': searchText === 'Enhancement'}">
				<p><strong>Enhancement</strong></p>
				<h2 class="bxt"><strong>{{aprrovalDataList?.enhancementCount}}</strong></h2>
				<p class="bxtt">Approval</p>
			</div>
		</a>

		<a class="col-md-3 text-decoration-none" (click)="updateSearchText('Discharge')">
			<div class="healboxx healshadow1" [ngClass]="{'selected': searchText === 'Discharge'}">
				<p><strong>Discharge</strong></p>
				<h2 class="bxt"><strong>{{aprrovalDataList?.dischargeCount}}</strong></h2>
				<p class="bxtt">Approval</p>
			</div>
		</a>

		<a class="col-md-3 text-decoration-none" (click)="updateSearchText('Final Claim')">
			<div class="healboxx healshadow2" [ngClass]="{'selected': searchText === 'Final Claim'}">
				<p><strong>Final Claim</strong></p>
				<h2 class="bxt"><strong>{{aprrovalDataList?.finalClaimCount}}</strong></h2>
				<p class="bxtt">Approval</p>
			</div>
		</a>
	</div>



	<div class="row mb-3 mt-5 text-center" *ngIf="showPending">


		<a class="col-md-3 text-decoration-none" (click)="updateSearchText('Initial Authorization')">
			<div class="healboxx healshadow1" [ngClass]="{'selected': searchText === 'Initial Authorization'}">
				<p><strong>Initial Authorization</strong></p>
				<h2 class="bxt"><strong>{{pendingDataList?.initialAuthorizationCount}}</strong></h2>
				<!-- {{statusDetail["initialAuthorizationCount"]}} -->
				<p class="bxtt">Pending</p>
			</div>
		</a>

		<a class="col-md-3 text-decoration-none" (click)="updateSearchText('Enhancement')">
			<div class="healboxx healshadow1" [ngClass]="{'selected': searchText === 'Enhancement'}">
				<p><strong>Enhancement</strong></p>
				<h2 class="bxt"><strong>{{pendingDataList?.enhancementCount}}</strong></h2>
				<p class="bxtt">Pending</p>
				<!-- {{statusDetail["Enhancement"]}} -->
			</div>
		</a>

		<a class="col-md-3 text-decoration-none" (click)="updateSearchText('Discharge')">
			<div class="healboxx healshadow1" [ngClass]="{'selected': searchText === 'Discharge'}">
				<p><strong>Discharge</strong></p>
				<h2 class="bxt"><strong>{{pendingDataList?.dischargeCount}}</strong></h2>
				<!-- {{statusDetail["Discharge"]}} -->
				<p class="bxtt">Pending</p>
			</div>
		</a>

		<a class="col-md-3 text-decoration-none" (click)="updateSearchText('Final Claim')">
			<div class="healboxx healshadow1" [ngClass]="{'selected': searchText === 'Final Claim'}">
				<p><strong>Final Claim</strong></p>
				<h2 class="bxt"><strong>{{pendingDataList?.finalClaimCount}}</strong></h2>
				<p class="bxtt">Pending</p>
				<!-- {{statusDetail["Final Claim"]}} -->
			</div>
		</a>
	</div>


	<div class="row mb-3 mt-5 text-center" *ngIf="showClosed">


		<a class="col-md-3 text-decoration-none">
			<div class="healboxx healshadow1">
				<p><strong>Initial Authorization</strong></p>
				<h2 class="bxt"><strong>{{closedDataList?.initialAuthorizationCount}}</strong></h2>
				<p class="bxtt">Closed</p>
			</div>
		</a>

		<a class="col-md-3 text-decoration-none">
			<div class="healboxx healshadow1">
				<p><strong>Enhancement</strong></p>
				<h2 class="bxt"><strong>{{closedDataList?.enhancementCount}}</strong></h2>
				<p class="bxtt">Closed</p>
			</div>
		</a>

		<a class="col-md-3 text-decoration-none">
			<div class="healboxx healshadow1">
				<p><strong>Discharge</strong></p>
				<h2 class="bxt"><strong>{{closedDataList?.dischargeCount}}</strong></h2>
				<p class="bxtt">Closed</p>
			</div>
		</a>

		<a class="col-md-3 text-decoration-none">
			<div class="healboxx healshadow1">
				<p><strong>Final Claim</strong></p>
				<h2 class="bxt"><strong>{{closedDataList?.finalClaimCount}}</strong></h2>
				<p class="bxtt">Closed</p>
			</div>
		</a>
	</div>


	<div class="row">
		<div class="col-md-12">
			<ul class="nav nav-tabs" id="myTab" role="tablist">
				<li class="nav-item" role="presentation">
					<button class="nav-link healspantab active" id="home-tab" data-bs-toggle="tab"
						(click)="tabclick('Pending')" data-bs-target="#home-tab-pane" type="button" role="tab"
						aria-controls="home-tab-pane"
						aria-selected="true">{{sessionStorage.getItem('hospitalName')}}</button>
				</li>
				<li class="nav-item" role="presentation">
					<button class="nav-link healspantab" id="profile-tab" data-bs-toggle="tab"
						(click)="tabclick('Approval')" data-bs-target="#profile-tab-pane" type="button" role="tab"
						aria-controls="profile-tab-pane" aria-selected="false">Healspan</button>
				</li>
				<li class="nav-item" role="presentation">
					<button class="nav-link healspantab" id="profile-tab" data-bs-toggle="tab"
						(click)="tabclick('Closed')" data-bs-target="#closed-tab-pane" type="button" role="tab"
						aria-controls="profile-tab-pane" aria-selected="false">Closed</button>
				</li>

			</ul>
			<div style="position: relative; float: right; right: 0px;">
				<a href="#" data-bs-toggle="modal" data-bs-target="#modalChoice3"
					style="position: absolute; right: -10px; top: -60px; width: 150px;"
					class="btn btn-primary btn-lg float-end mx-3 "><img src="assets/img/create.svg" height="18"
						width="18" class="float=start"> Create</a>
				<div class="modal" role="dialog" id="modalChoice3">
					<div class="modal-dialog healm" role="document">
						<ul class="healmenulist">
							<a href="/createclaim/Initial Authorization">
								<li class="healmenu">Initial Authorization</li>
							</a>
							<a href="/createclaim/Enhancement">
								<li class="healmenu">Enhance</li>
							</a>
							<a href="/createclaim/Discharge">
								<li class="healmenu">Discharge</li>
							</a>
							<a href="/createclaim/Final Claim">
								<li class="healmenu">Final Claim</li>
							</a>
						</ul>
					</div>
				</div>
			</div>

			<div class="tab-content pt-2 px-0" id="myTabContent">
				<div class="search-hero w-100 p-1">
					<input class="form-control mt-2" type="text" name="filter" [(ngModel)]="searchText"
						autocomplete="off" placeholder="Search Here" />
				</div>

				<div class="tab-pane fade show active" *ngIf="aprrovalDataList" id="home-tab-pane" role="tabpanel"
					aria-labelledby="home-tab" tabindex="0">
					<div class="table table-responsive fixTableHead">
						<table class="table tstriped table-borderless healshadow1">
							<thead class="theadheal">
								<tr>
									<th [appSort]="pendingDataList['pendingList']" data-order="desc" data-name="claimID"
										class="p-1">Claim ID

									</th>
									<th [appSort]="pendingDataList['pendingList']" data-order="desc" data-name="claimID"
										class="p-1">TPA Claim Number

									</th>
									<th [appSort]="pendingDataList['pendingList']" data-order="desc" data-name="name">
										Name
									</th>
									<th [appSort]="pendingDataList['pendingList']" data-order="desc" data-name="ailment"
										width="200"> Diagnosis </th>

									<th [appSort]="pendingDataList['pendingList']" data-order="desc" data-name="stage">
										Stage
									</th>
									<th [appSort]="pendingDataList['pendingList']" data-order="desc" data-name="status">
										Status
									</th>
									<th [appSort]="pendingDataList['pendingList']" data-order="desc"
										data-name="admissionDate">Admission Date
									</th>
									<th [appSort]="pendingDataList['pendingList']" data-order="desc"
										data-name="dischargeDate">Discharge Date
									</th>
									<th [appSort]="pendingDataList['pendingList']" data-order="desc" data-name="ageing">
										Ageing
									</th>
									<th [appSort]="pendingDataList['pendingList']" data-order="desc"
										data-name="approvedAmount">Approved Amount
									</th>

									<!--//new-->

									<!-- <th>Claim ID</th>
						<th>Name</th>
						<th>Ailment</th>
						<th>Stage</th>
						<th>Status</th>
						<th>Admission Date</th>
						<th>Discharge Date</th>
						<th>Ageing</th>
						<th>Approved Amount</th> -->
								</tr>

							</thead>
							<tbody>

								<tr *ngFor="let a of pendingDataList['pendingList'] | filter: searchText">
									<td><a href="javascript:void(0)" (click)="GotoClaim(a.stage,a.claimStageLinkId)"
											class="tblink">{{a.healspanClaimID}}</a></td>
									<td>{{a.tpaClaimNumber}}</td>
									<td>{{a.name}}</td>
									<td>{{a.ailment}}</td>
									<td>{{a.stage}}</td>
									<td>{{a.status}}</td>
									<td>{{a.admissionDate | date:'dd-MM-YYYY hh:mm a'}}</td>
									<td>{{a.dischargeDate | date:'dd-MM-YYYY hh:mm a'}}</td>
									<td>{{a.ageing}}</td>
									<td>{{a.approvedAmount}}</td>
								</tr>

							</tbody>
						</table>
					</div>
				</div>

				<div class="tab-pane fade" *ngIf="pendingDataList" id="profile-tab-pane" role="tabpanel"
					aria-labelledby="profile-tab" tabindex="0">
					<div class="table table-responsive fixTableHead">
						<table class="table tstriped table-borderless healshadow1">
							<thead class="theadheal">
								<th style="width: 135px;" [appSort]="aprrovalDataList['approvalList']" data-order="desc"
									data-name="claimID">Claim ID

								</th>
								<th [appSort]="aprrovalDataList['approvalList']" data-order="desc" data-name="claimID"
									class="p-1">TPA Claim Number

								</th>
								<th [appSort]="aprrovalDataList['approvalList']" data-order="desc" data-name="name">Name

								</th>
								<th [appSort]="aprrovalDataList['approvalList']" data-order="desc" data-name="ailment"
									width="200"> Diagnosis

								</th>

								<th [appSort]="aprrovalDataList['approvalList']" data-order="desc" data-name="stage">
									Stage

								</th>
								<th [appSort]="aprrovalDataList['approvalList']" data-order="desc" data-name="status">
									Status

								</th>
								<th [appSort]="aprrovalDataList['approvalList']" data-order="desc"
									data-name="admissionDate">AdmissionDate

								</th>
								<th [appSort]="aprrovalDataList['approvalList']" data-order="desc"
									data-name="dischargeDate">DischargeDate

								</th>
								<th [appSort]="aprrovalDataList['approvalList']" data-order="desc" data-name="ageing">
									Ageing

								</th>
								<th [appSort]="aprrovalDataList['approvalList']" data-order="desc"
									data-name="approvedAmount">ApprovedAmount

								</th>
							</thead>

							<tbody>
								<ng-container *ngIf="aprrovalDataList['approvalList']!= null">
									<tr *ngFor="let p of aprrovalDataList['approvalList'] | filter: searchText">
										<td><a scope="col" href="javascript:void(0)"
												(click)="GotoClaim(p.stage,p.claimStageLinkId)"
												class="tblink">{{p.healspanClaimID}}</a></td>
										<td>{{p.tpaClaimNumber}}</td>
										<td>{{p.name}}</td>
										<td>{{p.ailment}}</td>
										<td>{{p.stage}}</td>
										<td>{{p.status}}</td>
										<td>{{p.admissionDate |date:'dd-MM-YYYY hh:mm a'}}</td>
										<td>{{p.dischargeDate|date:'dd-MM-YYYY hh:mm a'}}</td>
										<td>{{p.ageing}}</td>
										<td>{{p.approvedAmount}}</td>
									</tr>
								</ng-container>
							</tbody>
						</table>
					</div>
				</div>


				<div class="tab-pane fade" *ngIf="closedDataList" id="closed-tab-pane" role="tabpanel"
					aria-labelledby="profile-tab" tabindex="0">
					<div class="table table-responsive fixTableHead">
						<table class="table tstriped table-borderless healshadow1">
							<thead class="theadheal">
								<th style="width: 135px;" [appSort]="closedDataList['closedList']" data-order="desc"
									data-name="claimID">Claim ID

								</th>
								<th [appSort]="closedDataList['closedList']" data-order="desc" data-name="claimID"
									class="p-1">TPA Claim Number

								</th>
								<th [appSort]="closedDataList['closedList']" data-order="desc" data-name="name">Name

								</th>
								<th [appSort]="closedDataList['closedList']" data-order="desc" data-name="ailment"
									width="200"> Diagnosis

								</th>

								<th [appSort]="closedDataList['closedList']" data-order="desc" data-name="stage">
									Stage

								</th>
								<th [appSort]="closedDataList['closedList']" data-order="desc" data-name="status">
									Status

								</th>
								<th [appSort]="closedDataList['closedList']" data-order="desc"
									data-name="admissionDate">AdmissionDate

								</th>
								<th [appSort]="closedDataList['closedList']" data-order="desc"
									data-name="dischargeDate">DischargeDate

								</th>
								<th [appSort]="closedDataList['closedList']" data-order="desc" data-name="ageing">
									Ageing

								</th>
								<th [appSort]="closedDataList['closedList']" data-order="desc"
									data-name="approvedAmount">ApprovedAmount

								</th>
							</thead>

							<tbody>
								<ng-container *ngIf="closedDataList['closedList'] != null">
									<tr *ngFor="let p of closedDataList['closedList'] | filter: searchText">
										<td><a scope="col" href="javascript:void(0)"
												(click)="GotoClaim(p.stage,p.claimStageLinkId)"
												class="tblink">{{p.healspanClaimID}}</a></td>
										<td>{{p.tpaClaimNumber}}</td>
										<td>{{p.name}}</td>
										<td>{{p.ailment}}</td>
										<td>{{p.stage}}</td>
										<td>{{p.status}}</td>
										<td>{{p.admissionDate |date:'dd-MM-YYYY hh:mm a'}}</td>
										<td>{{p.dischargeDate|date:'dd-MM-YYYY hh:mm a'}}</td>
										<td>{{p.ageing}}</td>
										<td>{{p.approvedAmount}}</td>
									</tr>
								</ng-container>
							</tbody>
						</table>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>