import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-hospital-info',
  templateUrl: './hospital-info.component.html',
  styleUrls: ['./hospital-info.component.css'],
})
export class HospitalInfoComponent {
  hospitalForm: FormGroup;

  hospitalResponse: any; // Declare a variable to store the hospital response
  loading: boolean = false; // Initialize the loading flag as false

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar // Inject MatSnackBar
  ) {
    this.createForm();
  }

  createForm() {
    this.hospitalForm = this.fb.group({
      hospitalName: ['', [Validators.required, Validators.minLength(3)]], // Example validation rules
      aboutUs: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      contact: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      email: ['', [Validators.required, Validators.email]], // Email validation here
      designation: ['', Validators.required],
      address: ['', Validators.required],
      contactNumber: [
        '',
        // [
        //   Validators.required,
        //   Validators.pattern('^[0-9]*$'),
        //   Validators.minLength(10),
        //   Validators.maxLength(10),
        // ],
      ],
      emailId: ['', [Validators.required, Validators.email]],
      gstNumber: ['', Validators.required],
      hospitalId: ['', Validators.required],
      hasLoan: [false],
    });
  }

  // Function to open a snackbar
  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Display for 3 seconds
    });
  }

  onHasLoanChange(event: any) {
    this.hospitalForm.patchValue({
      hasLoan: event.target.checked, // Set the hasLoan value to the checkbox state
    });
    console.log(this.hospitalForm.value);
  }

  onSubmit() {
    if (this.hospitalForm.valid) {
      const formData = this.hospitalForm.value;

      // const loanPrefix = formData.hasLoan ? 'L' : '';
      this.loading = true;

      const apiData = {
        name: formData.hospitalName,
        about: formData.aboutUs,
        address: formData.address,
        board_line_num: formData.contactNumber,
        email_id: formData.emailId,
        gst_num: formData.gstNumber,
        hospital_id: formData.hospitalId,
        has_loan: formData.hasLoan,
      };

      // Send hospital data and store the response
      this.http
        .post(`${environment.baseUrlOnBoard}/api/hospitals/`, apiData)
        .subscribe(
          (response) => {
            const hospitalRes = response; // Store the response

            // Extract and submit contact details to the API using the stored response
            if (Array.isArray(hospitalRes)) {
              const contactData = {
                contact: formData.contact,
                email: formData.email,
                firstname: formData.firstname,
                is_active: 'Y',
                lastname: formData.lastname,
                hospital_mst: hospitalRes[0].id, // Use the stored response
                designation: formData.designation,
              };

              // Send contact data
              this.http
                .post(`${environment.baseUrlOnBoard}/api/contect/`, contactData)
                .subscribe(
                  (contactResponse) => {
                    this.openSnackBar('User data submitted successfully');
                  },
                  (contactError) => {
                    console.error(
                      'Error submitting contact data:',
                      contactError
                    );
                    this.loading = false;
                  }
                );

              if (formData.hasLoan == true) {
                const contactData = {
                  contact: formData.contact,
                  email: formData.email,
                  firstname: formData.firstname,
                  is_active: 'Y', // Assuming 'Y' is the default value for is_active
                  lastname: formData.lastname,
                  hospital_mst: hospitalRes[1].id, // Use the stored response
                  designation: formData.designation,
                };

                // Send contact data
                this.http
                  .post(
                    `${environment.baseUrlOnBoard}/api/contect/`,
                    contactData
                  )
                  .subscribe(
                    (contactResponse) => {
                      this.openSnackBar('User data submitted successfully');
                    },
                    (contactError) => {
                      console.error(
                        'Error submitting contact data:',
                        contactError
                      );
                      this.loading = false;
                    }
                  );
              }

              const userData = {
                mobile_no: formData.contact,
                email: formData.email,
                first_name: formData.hospitalName,
                is_active: 'Y',
                last_name: '',
                username: `${formData.hospitalName}_user`, // Use a pattern with a timestamp for uniqueness
                hospital_mst: hospitalRes[0].id,
                user_role_mst: 2,
                hospital_main_mst: 1,
                name: formData.hospitalName,
              };
              this.http
                .post(`${environment.baseUrlOnBoard}/api/user/`, userData)
                .subscribe(
                  (userResponse) => {
                    this.hospitalForm.reset();
                    this.loading = false;
                  },
                  (userError) => {
                    console.error('Error submitting user data:', userError);
                  }
                );

              if (formData.hasLoan == true) {
                this.http
                  .post(`${environment.baseUrlOnBoard}/api/create_loan_user/`, {
                    mobile_no: formData.contact,
                    email: formData.email,
                    first_name: formData.hospitalName,
                    is_active: 'Y',
                    last_name: '',
                    username: `${formData.hospitalName}_loan`, // Use a pattern with a timestamp for uniqueness
                    hospital_mst: hospitalRes[1].id, // Use the second ID
                    user_role_mst: 2,
                    hospital_main_mst: 1,
                    name: formData.hospitalName,
                  })
                  .subscribe(
                    (contactResponse) => {
                      this.openSnackBar('User data submitted successfully');
                    },
                    (contactError) => {
                      console.error(
                        'Error submitting contact data:',
                        contactError
                      );
                      this.loading = false;
                    }
                  );
              }
            }
          },
          (error) => {
            console.error('Error submitting hospital data:', error);
            this.loading = false;
          }
        );
    } else {
      this.openSnackBar('User data is invalid');
      this.loading = false;
    }
  }
}

// Call the encrypt endpoint to generate a password
// this.http.get('${environment.baseUrlOnBoard}/api/encrypt/').subscribe(
//   (passwordResponse: any) => {
//     // Ensure you specify the type as 'any'
//     console.log('Password generated:', passwordResponse);

//     // Access the 'encrypted_text' property within the response object
//     const generatedPassword = passwordResponse.encrypted_text;

//     // Send user data with the generated password
//     const userData = {
//       mobile_no: formData.contact,
//       email: formData.email,
//       first_name: formData.firstname,
//       is_active: 'Y',
//       last_name: formData.lastname,
//       username: formData.firstname + formData.lastname,
//       password: generatedPassword,
//       hospital_mst: 1,
//       user_role_mst: 2,
//       hospital_main_mst: 1,
//     };

//   },
//   (passwordError) => {
//     console.error('Error generating password:', passwordError);
//   }
// );
