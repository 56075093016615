<div class="container fixed-footer">
  <div class="overlay-container" *ngIf="isLoading">
    <div class="overlay"></div>
    <div class="loader-container">
      <div class="loader"></div>
    </div>
  </div>
  <div class="report">

    <!-- <h1 class=" align-self-center mb-3">Report Generation *ngIf="!selectedReport"</h1> -->
    <div class="nav-item dropdown" *ngIf="usertype!='2'">

    <div class="dropdown" (click)="listenForClickOutside()">
      <button class="nav-link dropdown-toggle heallink download-button" href="/reports" id="reportsDropdown"
        role="button" data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true" (click)="toggleDropdown()">
        Report Download
      </button>
      <ul class="dropdown-menu" aria-labelledby="reportsDropdown">
        <li>
          <a class="dropdown-item" href="javascript:void(0);" (click)="setReport('Daily-Claims');">Daily-Claims</a>
          <a class="dropdown-item" href="javascript:void(0);" (click)="setReport('Daily-Denial');">Daily-Denial</a>
          <a class="dropdown-item" href="javascript:void(0);" (click)="setReport('TPA-Queries');">TPA-Queries</a>
        </li>
      </ul>
    </div>
    <p class="report__name">{{ selectedReport }}</p>

  <div class="btn-group btn-group-toggle toggle-button" data-toggle="buttons">
    <label class="btn btn-secondary active">
      <input type="radio" name="options" id="displayOption" autocomplete="off" (click)="toggleDisplay()"> Display
    </label>
    <label class="btn btn-secondary">
      <input type="radio" name="options" id="downloadOption" autocomplete="off" (click)="toggleDownload()"> Download
    </label>
  </div>
  <button class="btn btn-primary ok-button" (click)="okClicked()">OK</button>

    <!-- <div class="table__container">
      <table *ngIf="structuredData" class="table table-bordered">
        <thead>
          <tr>
            <th *ngFor="let header of structuredData[0]">{{ header }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of structuredData.slice(1)">
            <td *ngFor="let cell of row" class="table-cell">{{ cell }}</td>
          </tr>
        </tbody>
      </table>
    </div> -->
    <ng-container *ngIf="selectedReport == 'Daily-Claims'">
    <div  *ngIf="structuredData.length > 0" class="table__container">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>HEALSPAN_CLAIM_ID</th>
            <th>FULL_NAME</th>
            <th>CREATED_DATE_TIME</th>
            <th>LAST_UPDATED_DATE_TIME</th>
            <th>APPROVAL_TIMESTAMP</th>
            <th>DATE_OF_ADMISSION</th>
            <th>DATE_OF_DISCHARGE</th>
            <th>AILMENT</th>
            <th>CLAIM_STAGE</th>
            <th>CLAIM_STATUS</th>
            <th>CLAIMED_AMOUNT</th>
            <th>TPA_APPROVED_AMOUNT</th>
            <th>HOSPITAL_NAME</th>
            <th>USER_MST_ID</th>
            <th>SLA_PERCENT</th>
            <th>REVIEWER_NAME</th>
            <th>USERNAME</th>
            <th>USER_ROLE</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of getKeyValues()">
            <ng-container *ngFor="let cell of item">
              <td>{{ cell }}</td>
            </ng-container>
          </tr>
        </tbody>
      </table>
      
      <mat-paginator
      [length]="totalItems"
      [pageSize]="pageSize"
      [pageSizeOptions]="[10, 20, 50]"
      (page)="onPageChange($event)"
    ></mat-paginator>
    
    </div>
  </ng-container>
    <ng-container *ngIf="selectedReport == 'Daily-Denial'">
    <div  *ngIf="structuredData.length > 0" class="table__container">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>HS_Claim_Id</th>
            <th>TPA_Claim_Id</th>
            <th>Insurance_Company</th>
            <th>TPA</th>
            <th>Hospital</th>
            <th>Stage</th>
            <th>TPA_Comments</th>
            <th>Claim_Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of getKeyValues()">
            <ng-container *ngFor="let cell of item">
              <td>{{ cell }}</td>
            </ng-container>
          </tr>
        </tbody>
      </table>
      
      <mat-paginator
      [length]="totalItems"
      [pageSize]="pageSize"
      [pageSizeOptions]="[10, 20, 50]"
      (page)="onPageChange($event)"
    ></mat-paginator>
    
    </div>
  </ng-container>
    <ng-container *ngIf="selectedReport == 'TPA-Queries' ">
    <div  *ngIf="structuredData.length > 0" class="table__container">
      <table class="table table-bordered">
        <thead>
          <th>HOSPITAL</th>
    <th>HEALSPAN_CLAIM_ID</th>
    <th>TPA_CLAIM_NUMBER</th>
    <th>CREATED_DATETIME</th>
    <th>REMARKS</th>
    <th>STATUS</th>
    <th>STAGE</th>
        </thead>
        <tbody>
          <tr *ngFor="let item of getKeyValues()">
            <ng-container *ngFor="let cell of item">
              <td>{{ cell }}</td>
            </ng-container>
          </tr>
        </tbody>
      </table>
      
      <mat-paginator
      [length]="totalItems"
      [pageSize]="pageSize"
      [pageSizeOptions]="[10, 20, 50]"
      (page)="onPageChange($event)"
    ></mat-paginator>
    
    </div>
  </ng-container>

  
    

    <!-- <nav *ngIf="totalPages > 1">
      <ul class="pagination">
        <li class="page-item" [class.disabled]="currentPage === 1">
          <a class="page-link" (click)="goToPage(currentPage - 1)">Previous</a>
        </li>
        <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage === page">
          <a class="page-link" (click)="goToPage(page)">{{ page }}</a>
        </li>
        <li class="page-item" [class.disabled]="currentPage === totalPages">
          <a class="page-link" (click)="goToPage(currentPage + 1)">Next</a>
        </li>
      </ul>
    </nav> -->
    
    <!-- <nav *ngIf="totalPages > 1">
      <ul class="pagination">
        <li class="page-item" [class.disabled]="currentPage === 1">
          <a class="page-link" (click)="goToPage(currentPage - 1)">Previous</a>
        </li>
        <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage === page">
          <a class="page-link" (click)="goToPage(page)">{{ page }}</a>
        </li>
        <li class="page-item" [class.disabled]="currentPage === totalPages">
          <a class="page-link" (click)="goToPage(currentPage + 1)">Next</a>
        </li>
      </ul>
    </nav> -->

</div>