
<h3 class="modal-title modal-header healspantheme py-2 px-3">Add Other Costs Data <button  class="btn btn-primary float-end"  (click)="onClose(false)">
  Submit
</button></h3>
<!-- <button style="text-align: right;" tabindex="-1" mat-flat-button class="btn-margin" (click)="onClose(false)" color="primary">Cancel</button> -->


<!-- <div mat-dialog-title style="float: right; font-weight: 700; cursor: pointer;" (click)="onClose(false)">X</div> -->

<div class="row mt-3 mx-0">
  <div class="col-md-12">
    <mat-table #table [dataSource]="dataSource">
      <!-- <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> id </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
      </ng-container> -->

		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef> OtherCost Name </mat-header-cell>
			<mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
		</ng-container>

		<ng-container matColumnDef="value">
			<mat-header-cell *matHeaderCellDef>Cost Value</mat-header-cell>
			<mat-cell *matCellDef="let element">
				<mat-form-field floatLabel="never" >
                    <!-- -->
					<input matInput type="number" (keypress)="numericOnly($event)" placeholder="" [value]="element.value" [(ngModel)]="element.value">
				</mat-form-field>
			</mat-cell>
		</ng-container>

	
		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

	</mat-table>
</div>
  <!-- <div class="row">
    <div class="col-md-12">
    <button class="btn btn-primary float-end mt-3" (click)="onClose(false)">
      Save & Close
    </button>
    </div>
  </div> -->
</div>

<!-- <div class="container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
      <ng-container matColumnDef="OtherC_name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element">  </td>
      </ng-container>
     
      <ng-container matColumnDef="OtherC_value">
        <th mat-header-cell *matHeaderCellDef> UserName </th>
        <td mat-cell *matCellDef="let element">  </td>
      </ng-container>
    
     
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    
  </div>
   -->