import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonserviceService } from 'src/app/service/commonservice.service';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import bootstrap from 'src/assets/js/bootstrap';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  activeurl:string ="";
  usertype:any;
  constructor(private commonservice:CommonserviceService,private router:Router,private http: HttpClient) { }

  ngOnInit(): void {
    this.usertype = sessionStorage.getItem("usertype");
    new bootstrap.Dropdown(document.getElementById('reportsDropdown'));
  }

  redirect(){
    let activeDashboard =  sessionStorage.getItem("usertype");
    sessionStorage.setItem('Header', 'Dashboard');

    if(activeDashboard === '2'){
      this.activeurl ='/hdashboard';
      // this.router.navigate(['hdashboard'])

    }else{
      this.activeurl ='/rdashboard';
      // this.router.navigate(['rdashboard'])
    }
  }

  OnprofileSelect(){
    sessionStorage.setItem('Header', 'Profile');
  }

  OnreportSelect(){
    sessionStorage.setItem('Header', 'Report');
  }

//   OnReportSelect(report : string) {
//     let activeDashboard =  sessionStorage.getItem("usertype");
//     if(activeDashboard !== '2'){
//     let apiUrl = `${environment.baseUrlDocgen}api/retrieve/send_report/${report}`;
//     this.http.get(apiUrl, { responseType: 'blob' }).subscribe(
//       (data) => {
//         const blob = new Blob([data], { type: 'application/zip' });
//         const url = window.URL.createObjectURL(blob);
//         const anchor = document.createElement('a');
//         anchor.href = url;
//         anchor.download = 'reports.zip';
//         anchor.click();
//         window.URL.revokeObjectURL(url);
//       },
//       (error) => {
//         console.error('Error fetching the zip file:', error);
//       }
//     );
//   }
//   else {
//     console.log("Hospital users are not allowed to access this feature.");
//   }
// }

OnReportSelect(report: string) {
  let activeDashboard = sessionStorage.getItem("usertype");
  if (activeDashboard !== '2') {
    let apiUrl = `${environment.baseUrlDocgen}api/retrieve/send_report/${report}`;
    this.http.get(apiUrl, { responseType: 'text' }).subscribe(
      (data) => {
        const csvContent = data.replace(/\n/g, '\r\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' }); 
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.setAttribute('download', `${report}.csv`); 
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor); 
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.error('Error fetching the CSV file:', error);
      }
    );
  } else {
    console.log("Hospital users are not allowed to access this feature.");
  }
}

}
