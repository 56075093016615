<!-- <div mat-dialog-content   class="mat-dialog-content">
    <h2>{{message}}</h2>
   
  </div> -->


 <mat-dialog-content class="py-3 px-5">
      <h3>{{message}}          </h3>
    </mat-dialog-content>
    <mat-dialog-actions align="center" *ngIf="message=='Session timed out!'" >
      <button  mat-raised-button class="btn btn-primary btn-sm rounded w-25" mat-dialog-close tabindex="-1" >Ok</button>
    </mat-dialog-actions>