<main>
      <app-sidebar></app-sidebar>
      <div class="container-fluid  border-bottom shadow">
            <div class="container">
                  <app-header></app-header>
            </div>
      </div>
      <div class="mb-5">
            <router-outlet></router-outlet>
      </div>
</main>

<app-footer></app-footer>