<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
    <!-- <div class="offcanvas-header">
        <img src="img/Healspan-Final-Logo.png" class="float-end">
    </div> -->
    <div class="offcanvas-body nopadding pdrl0">
        <ul class="navbar-nav justify-content-end flex-grow-1 menutxt heallink">
            <li class="nav-item">
                <a class="nav-link heallink active" href="javascript:void(0)" aria-current="page"
                    (click)="redirect()">Dashboard</a>
            </li>
            <li class="nav-item">
                <a class="nav-link heallink">Reports</a>
            </li>
            <li class="nav-item">
                <a class="nav-link heallink">Network</a>
            </li>
        </ul>
    </div>
</div>
<header class="d-flex flex-wrap justify-content-center pt-3 mb-3 headershadow">
    <button class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample"
        aria-controls="offcanvasExample">
        <svg class="svg-inline--fa fa-bars" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars"
            role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
            <path fill="currentColor"
                d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z">
            </path>
        </svg>
    </button>
    <a href="javascript:void(0)" (click)="redirect()"
        class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
        <span class="fs-4">{{title}}</span>
    </a>

    <div class="">
        <div class="container">
            <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                <ul class="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">


                    <button mat-button [matMenuTriggerFor]="menu"
                        [ngClass]="{'disabled': checkNotifyData?.id == null   }"><i
                            class='fa fa-bell faa-ring animated fa-2x' [matBadge]="NotifyData?.length"
                            matBadgeColor="warn"></i></button>


                    <mat-menu #menu="matMenu" class="matmenu3" xPosition="before">
                        <div style=" background-color:#EBFAF8;  " class="py-3 px-3">
                            <strong>Notifications</strong>

                            <a class="hlcolor cursor-pointer float-end" (click)="Onreadall('YES')"><strong>Read
                                    All</strong></a>

                        </div>

                        <ul class="list-group rounded-0">
                            <ng-container *ngIf="NotifyData != null && NotifyData?.length >0">
                                <li class="list-group-item" *ngFor="let a of NotifyData "
                                    (click)="OnNotificSelect(a.id,'NO')">
                                    <a class="text-14 text-decoration-none" style="word-wrap: normal;">
                                        <h5 class="mb-0 hlcolor notification">{{a.notificationText}}</h5>
                                        <small class="text-secondary"> {{a.createdDatetime}}</small>
                                    </a>
                                </li>
                            </ng-container>
                        </ul>


                    </mat-menu>
                    <li>
                        <div class="flex-shrink-0 ">

                            <img src="/assets/img/user.png" height="25" class="px-2 mt-2 float-start">
                            <div class="float-start mt-2 py-1 fs-6"> {{firstName  +' '+lastName }}</div>



                        </div>
                    </li>
                    <li class="nav-item">
                        <a (click)="logout()">
                            <img src="/assets/img/turn-off.png" class="px-2 ps-4  mt-2 float-start" height="25">
                        </a>

                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>