<div class="container">
	<div class="row mb-3 mt-5 text-center d-flex justify-content-between">
		<!-- *ngFor="let a of spaData" -->
		<a href="" class="col-md-2 col-sm-2 hlbx1 healbox2 fs-5 text-decoration-none pt-4 mx-2" (click)="Sla('FirstBucket')"  href="javascript:void(0)" >
			<p>SLA < 25%</p>
			<h2>{{this.FirstBucket.length}}</h2>
			<!-- {{spaData.green}} -->
		</a>
		<a href="" class="col-md-2 col-sm-2 hlbx1 healbox2 fs-5 text-decoration-none pt-4 mx-2" (click)="Sla('SecondBucket')" href="javascript:void(0)" >
			<!-- /slastatus -->
			<p>SLA 25%-50%</p>
			<h2>{{this.SecondBucket.length}}</h2>
		</a>
		<a href="" class="col-md-2 col-sm-2 hlbx1 healbox2 fs-5 text-decoration-none pt-4 mx-2" (click)="Sla('ThirdBucket')" href="javascript:void(0)">
			<p>SLA 50%-75%</p>
			<h2>{{this.ThirdBucket.length}}</h2>
		</a>
		<a href="" class="col-md-2 col-sm-2 hlbx1 healbox2 fs-5 text-decoration-none pt-4 mx-2" (click)="Sla('FourthBucket')" href="javascript:void(0)" >
			<p>SLA 75%-100%</p>
			<h2>{{this.FourthBucket.length}}</h2>
		</a>
		<a href="" class="col-md-2 col-sm-2 hlbx1 healbox2 fs-5 text-decoration-none pt-4 mx-2" (click)="Sla('FifthBucket')" href="javascript:void(0)" >
			<p>SLA > 100%</p>
			<h2>{{this.FifthBucket.length}}</h2>
		</a>
	</div>
	
	<div class="row">
		<div class="col-md-12">
		<ul class="nav nav-tabs" id="myTab" role="tablist">
			<li class="nav-item" role="presentation">
				<button class="nav-link healspantab active" id="home-tab" data-bs-toggle="tab"
				(click)="tabclick('Healspan')" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane"
					aria-selected="false">Healspan</button>
			</li>
			<li class="nav-item" role="presentation">
				<button class="nav-link healspantab" id="profile-tab" data-bs-toggle="tab"
				(click)="tabclick('Hospital')"	data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane"
					aria-selected="false">Hospital</button>
			</li>
			<li class="nav-item " role="presentation">
				<button class="nav-link healspantab" id="contact-tab" data-bs-toggle="tab"
				(click)="tabclick('Closed')"	data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane"
					aria-selected="false">Closed</button>
			</li>
		</ul>
		<div style="position: relative; float: right; right: 0px;">
			<!-- <a href="#" data-bs-toggle="modal" data-bs-target="#modalChoice3" style="position: absolute; right: -10px; top: -60px; width: 150px;"
                class="btn btn-primary btn-lg float-end mx-3 " ><img src="assets/img/create.svg" height="18" width="18" class="float=start"> Create
			</a> -->
		<div class="modal" role="dialog" id="modalChoice3">
			<div class="modal-dialog healm" role="document">
				<ul class="healmenulist">
					<a href="/createclaim/Initial Authorization">
						<li class="healmenu">Initial Authorization</li>
					</a>
					<a href="/createclaim/Enhancement">
						<li class="healmenu">Enhance</li>
					</a>
					<a href="/createclaim/Discharge">
						<li class="healmenu">Discharge</li>
					</a>
					<a href="/createclaim/Final Claim">
						<li class="healmenu">Final Claim</li>
					</a>
				</ul>
			</div>
		</div>
		</div>
		<div class="tab-content pt-2 px-0" id="myTabContent" >
			<div class="search-hero w-100 p-1">
				<input class="form-control mt-2" type="text" name="filter" [(ngModel)]="searchText" autocomplete="off"
					placeholder="Search Here" />
			</div>
			<div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
				tabindex="0">
				<div class="table table-responsive fixTableHead">
				<table class="table tstriped table-borderless healshadow1">
					<thead style="position: sticky !important; top:0px !important;" class="theadheal">
						<th [appSort]="healspandata" data-order="desc" data-name="claimID">Claim ID </th>
						<th [appSort]="healspandata" data-order="desc" data-name="claimID">TPA Claim Number </th>
						<th [appSort]="healspandata" data-order="desc" data-name="name">Name &nbsp;</th>
						<th [appSort]="healspandata" data-order="desc" data-name="ailment" width="200"> Diagnosis &nbsp;</th>
						<!-- <th [appSort]="healspandata" data-order="desc" data-name="ptat">P-TAT &nbsp;</th> -->
						<th [appSort]="healspandata" data-order="desc" data-name="stage">Stage &nbsp;</th>
						<th [appSort]="healspandata" data-order="desc" data-name="status">Status &nbsp;</th>
						<th [appSort]="hospitalData" data-order="desc" data-name="createdDateTime">Last Updated &nbsp;</th>
						<th [appSort]="healspandata" data-order="desc" data-name="approvedAmount">Approved Amount &nbsp;</th>
						<!-- Check if user ID is 1 before rendering the "Reviewer" column -->
						<ng-container *ngIf='usertypeid == 1'>
						  <th [appSort]="healspandata" data-order="desc" data-name="reviwerName">Reviewer &nbsp;</th>
						</ng-container>
						<th [appSort]="healspandata" data-order="desc" data-name="Hospital">Hospital &nbsp;</th>
					  </thead>
					  
					<tbody>
						<tr *ngFor="let h of healspandata | filter: searchText">
							<td><a (click)="GotoClaim(h.claimStageLinkId)" href="javascript:void(0)" class="tblink">{{h.healspanClaimID}}</a></td>
							<td>{{h.tpaClaimNumber}}</td>
							<td>{{h.name}}</td>
							<td>{{h.ailment}}</td>
							<!-- <td>{{h.ptat}}</td> -->
							<td>{{h.stage}}</td>
							<td>{{h.status}}</td>
							<td>{{formatDate(h.updatedDateTime)}}</td>
							<td>{{h.approvedAmount}}</td>
							<ng-container *ngIf='usertypeid == 1'>
							<td>{{h.reviwerName}}</td>
						</ng-container>
							<td>{{h.hospital}}</td>
						</tr>
					</tbody>
				</table>
				</div>
			</div>
			<div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
				<div class="table table-responsive fixTableHead">
				<table class="table tstriped table-borderless healshadow1">
					<thead style="position: sticky !important; top:0px !important;" class="theadheal">
						<!-- <th>Claim ID</th> -->
						<th [appSort]="hospitalData" data-order="desc" data-name="claimID">Claim ID
							</th>
							<th [appSort]="healspandata" data-order="desc" data-name="claimID">TPA Claim Number
							</th>
						<th [appSort]="hospitalData" data-order="desc" data-name="name">Name &nbsp;</th>
						<th [appSort]="hospitalData" data-order="desc" data-name="ailment" > Diagnosis &nbsp;</th>
						<!-- <th [appSort]="hospitalData" data-order="desc" data-name="ptat">P-TAT &nbsp;</th> -->
						<th [appSort]="hospitalData" data-order="desc" data-name="stage">Stage &nbsp;</th>
						<th [appSort]="hospitalData" data-order="desc" data-name="status">Status &nbsp;</th>
						<th [appSort]="hospitalData" data-order="desc" data-name="approvedAmount">Approved Amount &nbsp;</th>
						<th [appSort]="hospitalData" data-order="desc" data-name="Hospital">Hospital &nbsp;</th>
					</thead>
					<tbody>
						<tr *ngFor="let h of hospitalData | filter: searchText">
							<td><a (click)="GotoClaim(h.claimStageLinkId)" href="javascript:void(0)" class="tblink">{{h.healspanClaimID}}</a></td>
							<td>{{h.tpaClaimNumber}}</td>
							<td>{{h.name}}</td>
							<td>{{h.ailment}}</td>
							<!-- <td>{{h.ptat}}</td> -->
							<td>{{h.stage}}</td>
							<td>{{h.status}}</td>
							<td>{{h.approvedAmount}}</td>
							<td>{{h.hospital}}</td>
						</tr>
					</tbody>
				</table>
				</div>
			</div>
			<div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
				<div class="table table-responsive fixTableHead">
				<table class="table tstriped table-borderless healshadow1">
					<thead style="position: sticky !important; top:0px !important;" class="theadheal">
						<th [appSort]="ClosedData" data-order="desc" data-name="claimID">Claim ID &nbsp;
							</th>
							<th [appSort]="healspandata" data-order="desc" data-name="claimID">TPA Claim Number
							</th>
						<th [appSort]="ClosedData" data-order="desc" data-name="name">Name &nbsp;</th>
						<th [appSort]="ClosedData" data-order="desc" data-name="ailment"> Diagnosis &nbsp;</th>
						<!-- <th [appSort]="ClosedData" data-order="desc" data-name="ptat">P-TAT &nbsp;</th> -->
						<th [appSort]="ClosedData" data-order="desc" data-name="stage">Stage &nbsp;</th>
						<th [appSort]="ClosedData" data-order="desc" data-name="status">Status &nbsp;</th>
						<th [appSort]="ClosedData" data-order="desc" data-name="approvedAmount">Approved Amount &nbsp;</th>
						<th [appSort]="ClosedData" data-order="desc" data-name="Hospital">Hospital &nbsp;</th>
					</thead>
					<tbody>
						<tr *ngFor="let h of ClosedData | filter: searchText">
							<td><a (click)="GotoClaim(h.claimStageLinkId)" href="javascript:void(0)" class="tblink">{{h.healspanClaimID}}</a></td>
							<td>{{h.tpaClaimNumber}}</td>
							<td>{{h.name}}</td>
							<td>{{h.ailment}}</td>
							<!-- <td>{{h.ptat}}</td> -->
							<td>{{h.stage}}</td>
							<td>{{h.status}}</td>
							<td>{{h.approvedAmount}}</td>
							<td>{{h.hospital}}</td>
						</tr>
					</tbody>
				</table>
				</div>
			</div>
		</div>
	</div>
	</div>
	
	</div>